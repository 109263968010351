import React from "react"
import Layout from "../../components/layout"
import ServiceBanner from "../../components/ServicePage/ServiceBanner"
import PartnerBlock from "../../components/ServicePage/PartnerBlock"
import ServiceList from "../../components/ServicePage/ServiceList"
import WDOffer from "../../components/ServicePage/WDOffer"
import ClientBox from "../../components/Common/ClientBox"
import Testimonials from "../../components/Common/Testimonials"
import Blog from "../../components/Common/Blog"
import { graphql, StaticQuery } from "gatsby"
import { useDispatch } from "react-redux"
import { SERVICE_PAGE_QUERY_SUCCESS } from "../../store/actionTypes/index"
import { getServiceQueryData } from "../../store/actions/servicepage"
import Seo from "../../components/seo"
const ServicePage = () => {
  const dispatch = useDispatch()

  const query = graphql`
    {
      wdwordpress {
        page(id: "cGFnZTozNjQ1=") {
          service_page_new {
            bannerSection {
              buttonText {
                en
                sv
              }
              description {
                en
                sv
              }
              image {
                title
                sourceUrl
              }
              title {
                en
                sv
              }
            }
            offerSection {
              boxImage {
                title
                sourceUrl
              }
              description {
                en
                sv
              }
              list {
                content {
                  count
                  description {
                    en
                    sv
                  }
                  title {
                    en
                    sv
                  }
                }
              }
              subtitle {
                en
                sv
              }
              title {
                en
                sv
              }
            }
            secondSection {
              description {
                en
                sv
              }
              list {
                content {
                  count
                  description {
                    en
                    sv
                  }
                  title {
                    en
                    sv
                  }
                }
              }
              subtitle {
                en
                sv
              }
              title {
                en
                sv
              }
            }
            serviceSection {
              description {
                en
                sv
              }
              list {
                content {
                  image {
                    title
                    sourceUrl
                  }
                  description {
                    en
                    sv
                  }
                  title {
                    en
                    sv
                  }
                }
              }
              subtitle {
                en
                sv
              }
              title {
                en
                sv
              }
            }
          }
        }
      }
    }
  `
  return (
    <StaticQuery
      query={query}
      render={({ wdwordpress }) => {
        dispatch(getServiceQueryData())
        dispatch({ type: SERVICE_PAGE_QUERY_SUCCESS, payload: wdwordpress })
        return (
          <>
            <Layout>
              <Seo
                title="Technologies - Python Developer"
                pageId="cGFnZTozNjQ1"
              />
              <ServiceBanner />
              <PartnerBlock />
              <ServiceList />
              <WDOffer />
              <ClientBox />
              <Testimonials />
              <Blog />
            </Layout>
          </>
        )
      }}
    />
  )
}

export default ServicePage
